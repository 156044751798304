import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import media from "styled-media-query";

// eslint-disable-next-line
import { squareImage } from "../image";

import MenuGrid from "../components/MenuGrid";
import Main from "../components/Main";

import { H2, SectionHeader } from "../components/Typo";
import QuoteBox from "../components/QuoteBox";
import Section from "../components/Section";
import Agenda from "../components/Agenda";
import MediaItem from "../components/MediaItem";

import { ID } from "../svg";

const IntroLeft = styled.div`
  flex: 1;
  padding: 48px 48px 32px 0px;

  ${media.lessThan("small")`
    padding: 0px;
    margin-bottom: 24px;
  `}

  ${media.lessThan("medium")`
    align-self: center;
    width: min(380px, 100% - 40px);
  `}

  & img {
    width: 90%;
    transform: rotate(-1deg);
    object-fit: none;
  }
`;

const IntroRight = styled.div`
  position: relative;
  flex: 1;
  padding: 32px 48px;
  border-radius: 2px;
  background-color: #f9f9f9;
  align-self: center;

  ${media.lessThan("small")`
    padding: 16px;

    & .id-icon {
      width: 24px !important;
    }
  `}
`;

const Media = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
  gap: 64px;

  ${media.lessThan("small")`
    flex-direction: column;
    align-items: center;
  `}

  ${media.lessThan("690px")`
      gap: 32px;
    `}
`;

export default () => {
  const data = useStaticQuery(graphql`
    query {
      hierishetnu: file(relativePath: { eq: "paars.jpg" }) {
        ...squareImage
      }
      ad: file(relativePath: { eq: "ad.jpg" }) {
        ...squareImage
      }
      eemlander: file(relativePath: { eq: "eemlander.jpg" }) {
        ...squareImage
      }
      leven: file(relativePath: { eq: "krantenartikel.webp" }) {
        ...squareImage
      }
    }
  `);

  return (
    <Main frontpage title="Home" path="/">
      <MenuGrid />

      <QuoteBox
        padding={64}
        quote=" Emma Lou bezingt haar kinderen, maar ook haar miskraam"
        source="AD, 2024"
        style={{
          maxWidth: 960,
          margin: "auto",
          boxSizing: "border-box",
          marginBottom: 64,
          marginTop: 76,
        }}
      />
      <Section flex noMargin>
        <IntroLeft>
          <Img
            fluid={data.hierishetnu.childImageSharp.fluid}
            alt={"emma"}
            fadeIn={true}
            durationFadeIn={100}
            style={{ height: "90%", maxWidth: 380 }}
            imgStyle={{ objectPosition: "25%" }}
          />
        </IntroLeft>
        <IntroRight>
          <H2>Over Emma Lou</H2>
          <ID
            className="id-icon"
            width="48px"
            style={{
              position: "absolute",
              right: 32,
              top: 24,
              transform: "rotate(10deg)",
              fill: "grey",
            }}
          />
          <p>
            Emma Lou brengt nieuwe muziek uit! <strong>'LEVEN'</strong> is de
            alomvattende titel van de vijf liedjes op haar gloednieuwe{" "}
            <strong>EP</strong>. Van een pril leven dat niet heeft mogen zijn,
            de intense liefde voor je kind, tot de uitdagingen die het leven met
            zich meebrengt: "Het draait en het duizelt, en je zit er middenin."
            Met haar openhartige teksten en rijke gevoelswereld weet Emma Lou
            recht je hart te raken. Een nieuwe fase in haar songwriting;
            volwassener en indringender. Verwacht melancholische melodieën,
            akoestische gitaarpartijen, stevige synths en dromerige vocalen,
            aangevuld met virtuoze strijkers.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link style={{ color: "#333" }} to="/wie">
              Lees verder...
            </Link>
          </div>
        </IntroRight>
      </Section>

      <Section id="agenda">
        <SectionHeader title="Agenda" center icon="agenda" />
        <Agenda />
      </Section>

      <Section>
        <SectionHeader title="Media" center icon="media" />
        <Media>
          <MediaItem
            imgUrl={data.leven.childImageSharp.fluid}
            title="Weekend dichtbij: 'Het is een onderschat verdriet'"
            source="AD, nov 2024"
            link="/krantenartikel.webp"
          />
          <MediaItem
            imgUrl={data.ad.childImageSharp.fluid}
            title="Amersfoortse zangeres zag Herman van Veen en dacht: 'Zo iemand wil ik zijn'"
            source="AD, nov 2017"
            link="https://www.ad.nl/amersfoort/amersfoortse-zangeres-zag-herman-van-veen-en-dacht-zo-iemand-wil-ik-zijn~afad793d/"
          />
          <MediaItem
            imgUrl={data.eemlander.childImageSharp.fluid}
            title="Het succes van Emma Lou"
            source="De Gooi- en Eemlander, sept 2017"
            link="https://www.gooieneemlander.nl/cnt/dmf20180926_63954800/het-succes-van-emma-lou?utm_source=google&utm_medium=organic"
          />
        </Media>
      </Section>
    </Main>
  );
};
